* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Exxpanded', sans-serif;
}

button.rec-dot{
  background-color: white;
  box-shadow: 0 0 0px 0px rgba(235, 16, 16, 0.5);
}

button.rec-dot:hover, button.rec-dot:active, button.rec-dot:focus  {
  box-shadow: 0 0 1px 3px rgba(255, 128, 0, 1);
}

button.rec-arrow-right, button.rec-arrow-left{
  background-color: white;
}

button.kHmfcy:hover:enabled, button.kHmfcy:focus:enabled{
  background-color: #ff8000;
}
